import { createHeatmapChart } from "./amcharts-heatmap.js";
import Papa from "papaparse";

let chart; // Declare a variable to hold the chart instance
let transformedData; // Declare a variable to hold the transformed data

function transformData(data) {
  const result = {};

  data.forEach((yearData) => {
    const year = yearData.state;
    Object.entries(yearData).forEach(([key, value]) => {
      if (key !== "state") {
        if (!result[key]) {
          result[key] = {};
        }
        result[key][year] = value;
      }
    });
  });

  return result;
}

function prepareDataForYear(data, year) {
  return Object.entries(data)
    .map(([stateId, stateData]) => ({
      id: stateId,
      name: stateId.slice(3), // Removes "US-" prefix, adjust if you have full state names
      value: stateData[year] * 100,
    }))
    .filter((item) => item.value !== undefined);
}

// Main function to load CSV and create heatmap
async function loadDataAndCreateHeatmap() {
  try {
    // Process data for heatmap
    transformedData = transformData(heatMapData2);
    const selectedYear = document.getElementById("yearDropdown").value;
    const dataForChart = prepareDataForYear(transformedData, selectedYear);

    console.log("Initial data for chart:", dataForChart);

    // Create the heatmap chart
    chart = createHeatmapChart("heatchartdiv", dataForChart);

    console.log("Initial chart created:", chart);

    // Set up event listener for the dropdown
    const yearDropdown = document.getElementById("yearDropdown");
    yearDropdown.addEventListener("change", (event) => {
      updateChart(event.target.value);
    });
  } catch (error) {
    console.error("Error loading data or creating heatmap:", error);
  }
}

// Function to update the chart with new data
function updateChart(year) {
  console.log("Updating chart for year:", year);
  const dataForChart = prepareDataForYear(transformedData, year);
  console.log("Prepared data:", dataForChart);

  if (chart) {
    console.log("Current chart object:", chart);
    if (typeof chart.dispose === "function") {
      console.log("Disposing old chart");
      chart.dispose(); // Dispose of the old chart
    } else {
      console.warn("Chart object does not have a dispose method");
    }
  } else {
    console.warn("Chart object is undefined before update");
  }

  console.log("Creating new chart");
  const newChart = createHeatmapChart("heatchartdiv", dataForChart);
  console.log("New chart created:", newChart);

  if (newChart) {
    chart = newChart;
  } else {
    console.error("createHeatmapChart did not return a chart object");
  }
}

// Assuming your original data is in a variable called 'originalData'
document.addEventListener("DOMContentLoaded", loadDataAndCreateHeatmap);

const heatMapData2 = [
  {
    state: 2014,
    "US-AK": 0.4462598711888497,
    "US-AL": 0.6704541691066825,
    "US-AR": 0.560262535687613,
    "US-AZ": 0.5585260494119914,
    "US-CA": 0.6424038276416977,
    "US-CO": 0.5227256621566337,
    "US-CT": 0.5352526116171125,
    "US-DC": 0.957471508931384,
    "US-DE": 0.7545948686130934,
    "US-FL": 0.885134186767831,
    "US-GA": 0.966366925658097,
    "US-HI": 0.6529336850151826,
    "US-IA": 0.3968331051375919,
    "US-ID": 0.39494124271212233,
    "US-IL": 0.7148290456723069,
    "US-IN": 0.4984068851118939,
    "US-KS": 0.6245249012671141,
    "US-KY": 0.6007738534019772,
    "US-LA": 0.9486157765868726,
    "US-MA": 0.6571878735899426,
    "US-MD": 0.7660237139822476,
    "US-ME": 0.35000036289175707,
    "US-MI": 0.5977663124119516,
    "US-MN": 0.45060084883804297,
    "US-MO": 0.5919321531517142,
    "US-MS": 1,
    "US-MT": 0.3649302226668794,
    "US-NC": 0.6716113272290599,
    "US-ND": 0.4164106318160759,
    "US-NE": 0.5042299540343591,
    "US-NH": 0.3889753024049615,
    "US-NJ": 0.7031074501426112,
    "US-NM": 0.5612374233537021,
    "US-NV": 0.6906265048239612,
    "US-NY": 0.8397950268663934,
    "US-OH": 0.4852260450206718,
    "US-OK": 0.6131234058602768,
    "US-OR": 0.2888971101305991,
    "US-PA": 0.6331163794155614,
    "US-RI": 0.5018581411246704,
    "US-SC": 0.7405946760206097,
    "US-SD": 0.38781023661493563,
    "US-TN": 0.6359751052953307,
    "US-TX": 0.741484134756183,
    "US-UT": 0.31775534701020536,
    "US-VA": 0.7020461402626157,
    "US-VT": 0.4595725035228485,
    "US-WA": 0.5343359136939265,
    "US-WI": 0.42461553549654957,
    "US-WV": 0.5957768535809974,
    "US-WY": 0.4650988277296695,
  },
  {
    state: 2015,
    "US-AK": 0.5308282336254689,
    "US-AL": 0.6845110821833214,
    "US-AR": 0.620467965599679,
    "US-AZ": 0.501572859045313,
    "US-CA": 0.6545137247152789,
    "US-CO": 0.48271980360490435,
    "US-CT": 0.5479503689287472,
    "US-DC": 1,
    "US-DE": 0.6165611419565941,
    "US-FL": 0.8514296965631177,
    "US-GA": 0.9839751235363946,
    "US-HI": 0.5746812617395702,
    "US-IA": 0.4337314423897706,
    "US-ID": 0.3588212380327824,
    "US-IL": 0.6589504170494196,
    "US-IN": 0.5499110281081434,
    "US-KS": 0.43481535492425993,
    "US-KY": 0.626107509108556,
    "US-LA": 0.8741800127110672,
    "US-MA": 0.5558994588280878,
    "US-MD": 0.7248848074934461,
    "US-ME": 0.4636996143592689,
    "US-MI": 0.6759188158150071,
    "US-MN": 0.40452799142694196,
    "US-MO": 0.5301118305869161,
    "US-MS": 0.9270676314411599,
    "US-MT": 0.40610220552075815,
    "US-NC": 0.6721617547850569,
    "US-ND": 0.3286627353752748,
    "US-NE": 0.46377374019361156,
    "US-NH": 0.34722321568555536,
    "US-NJ": 0.6420169028738963,
    "US-NM": 0.47305424174094424,
    "US-NV": 0.6833868310083974,
    "US-NY": 0.7954261219852037,
    "US-OH": 0.5267917652366643,
    "US-OK": 0.579717449711433,
    "US-OR": 0.3552157323760266,
    "US-PA": 0.5733481509346172,
    "US-RI": 0.605518568531049,
    "US-SC": 0.7812960991193842,
    "US-SD": 0.43448518355990656,
    "US-TN": 0.6377609111239285,
    "US-TX": 0.7727525160350116,
    "US-UT": 0.3550022576589948,
    "US-VA": 0.5694149809453036,
    "US-VT": 0.4366148166651709,
    "US-WA": 0.5151874768701877,
    "US-WI": 0.3833894075966382,
    "US-WV": 0.5287917954011042,
    "US-WY": 0.4088955861724539,
  },
  {
    state: 2016,
    "US-AK": 0.423763225915278,
    "US-AL": 0.7150267933807009,
    "US-AR": 0.533444739703213,
    "US-AZ": 0.4864186372890949,
    "US-CA": 0.5625690999066089,
    "US-CO": 0.4291282539822027,
    "US-CT": 0.5000345467616962,
    "US-DC": 0.8991095000789651,
    "US-DE": 0.6816096913112482,
    "US-FL": 0.8058516842896434,
    "US-GA": 0.9378480064108101,
    "US-HI": 0.45952397522040034,
    "US-IA": 0.3951184969628163,
    "US-ID": 0.324813201604244,
    "US-IL": 0.589880237102852,
    "US-IN": 0.5315638931302462,
    "US-KS": 0.3852950514903301,
    "US-KY": 0.5181129634851365,
    "US-LA": 0.9013928948394323,
    "US-MA": 0.5014981771410313,
    "US-MD": 0.7176251491230661,
    "US-ME": 0.38146008218927385,
    "US-MI": 0.5890697825346151,
    "US-MN": 0.36517318547327215,
    "US-MO": 0.49041627445982044,
    "US-MS": 1,
    "US-MT": 0.36813469325164855,
    "US-NC": 0.6568057205544351,
    "US-ND": 0.45121353245257884,
    "US-NE": 0.427744009077301,
    "US-NH": 0.3230781224168026,
    "US-NJ": 0.593685153198365,
    "US-NM": 0.5599005569584978,
    "US-NV": 0.5897958550860434,
    "US-NY": 0.7509726503592881,
    "US-OH": 0.5525453754684534,
    "US-OK": 0.4764286991504308,
    "US-OR": 0.40498649665954845,
    "US-PA": 0.5694443325579024,
    "US-RI": 0.4599109633541552,
    "US-SC": 0.7054454751750675,
    "US-SD": 0.38470699774137745,
    "US-TN": 0.5829588571100673,
    "US-TX": 0.7249265947347061,
    "US-UT": 0.32621564950910314,
    "US-VA": 0.6046518087081588,
    "US-VT": 0.36568720026985146,
    "US-WA": 0.42981325465635134,
    "US-WI": 0.3963156480479378,
    "US-WV": 0.47373893998487915,
    "US-WY": 0.45607299375798,
  },
  {
    state: 2017,
    "US-AK": 0.5606310148969037,
    "US-AL": 0.7913459779317253,
    "US-AR": 0.5728139919823576,
    "US-AZ": 0.538864219377834,
    "US-CA": 0.6420747639663646,
    "US-CO": 0.455151802548984,
    "US-CT": 0.5624662024844934,
    "US-DC": 0.914944255638638,
    "US-DE": 0.7871587414936667,
    "US-FL": 0.866487244920167,
    "US-GA": 0.9822225093887873,
    "US-HI": 0.5270156192983054,
    "US-IA": 0.4287265858832912,
    "US-ID": 0.37082945010605284,
    "US-IL": 0.6079556711391374,
    "US-IN": 0.5865995853943743,
    "US-KS": 0.4596774497835937,
    "US-KY": 0.5895132309834576,
    "US-LA": 1,
    "US-MA": 0.5453968925745728,
    "US-MD": 0.8544358998805793,
    "US-ME": 0.37693293641506537,
    "US-MI": 0.6356152986968081,
    "US-MN": 0.4057170271981497,
    "US-MO": 0.5433942409574802,
    "US-MS": 0.9997485864357307,
    "US-MT": 0.3534749224300999,
    "US-NC": 0.6989967861610303,
    "US-ND": 0.42086686230398684,
    "US-NE": 0.4946528625475574,
    "US-NH": 0.3710309032314986,
    "US-NJ": 0.6796779313559823,
    "US-NM": 0.5609039449798532,
    "US-NV": 0.6895832721471231,
    "US-NY": 0.8090489863672359,
    "US-OH": 0.5864444614713158,
    "US-OK": 0.5150861055511582,
    "US-OR": 0.4792725083516533,
    "US-PA": 0.6299260063906842,
    "US-RI": 0.5511447130606852,
    "US-SC": 0.7666767046475869,
    "US-SD": 0.41797534948250664,
    "US-TN": 0.6199527268154691,
    "US-TX": 0.7436174196442824,
    "US-UT": 0.3619248491391975,
    "US-VA": 0.7229517797885106,
    "US-VT": 0.49508128369471194,
    "US-WA": 0.49071809399059757,
    "US-WI": 0.4049639590519457,
    "US-WV": 0.5538301335224445,
    "US-WY": 0.42185495094753994,
  },
  {
    state: 2018,
    "US-AK": 0.4344413040533946,
    "US-AL": 0.7926161998990034,
    "US-AR": 0.537864486673773,
    "US-AZ": 0.5044764962458068,
    "US-CA": 0.5623946227228985,
    "US-CO": 0.4247074934455915,
    "US-CT": 0.5349989052938658,
    "US-DC": 0.8347604968340506,
    "US-DE": 0.7592979200573858,
    "US-FL": 0.7969742066241841,
    "US-GA": 0.9163190001626136,
    "US-HI": 0.448534214847374,
    "US-IA": 0.3986851708418846,
    "US-ID": 0.353306151279933,
    "US-IL": 0.568194987931106,
    "US-IN": 0.5208148333969796,
    "US-KS": 0.45149915273756974,
    "US-KY": 0.5557795499744534,
    "US-LA": 0.9704045741959056,
    "US-MA": 0.45229291865193394,
    "US-MD": 0.7780180313121164,
    "US-ME": 0.3709119642466727,
    "US-MI": 0.5699789456230163,
    "US-MN": 0.3609624624345519,
    "US-MO": 0.5286397998562763,
    "US-MS": 1,
    "US-MT": 0.40258538415040707,
    "US-NC": 0.6501029919103819,
    "US-ND": 0.40724265512383606,
    "US-NE": 0.4419999736888082,
    "US-NH": 0.37928751566460334,
    "US-NJ": 0.6629922344686017,
    "US-NM": 0.5125926542678779,
    "US-NV": 0.6390928694926908,
    "US-NY": 0.7275342018558119,
    "US-OH": 0.5756876617953873,
    "US-OK": 0.47581977217942084,
    "US-OR": 0.4650684836376871,
    "US-PA": 0.5637596320535743,
    "US-RI": 0.5232071552048048,
    "US-SC": 0.7036659050613326,
    "US-SD": 0.3542234917776249,
    "US-TN": 0.5625195590507561,
    "US-TX": 0.6922657523217038,
    "US-UT": 0.32964457413606424,
    "US-VA": 0.6206115804025332,
    "US-VT": 0.39991187219928065,
    "US-WA": 0.4288977383284099,
    "US-WI": 0.44652902828256763,
    "US-WV": 0.49336846746565427,
    "US-WY": 0.38229061548325527,
  },
  {
    state: 2019,
    "US-AK": 0.516321049460798,
    "US-AL": 0.7224703224342767,
    "US-AR": 0.6047614482315309,
    "US-AZ": 0.5293792980387859,
    "US-CA": 0.5955649995821428,
    "US-CO": 0.46180144655602856,
    "US-CT": 0.5358488255875171,
    "US-DC": 0.8780265350599686,
    "US-DE": 0.7076077724298919,
    "US-FL": 0.8210872017194351,
    "US-GA": 1,
    "US-HI": 0.48590942291451356,
    "US-IA": 0.40681570917320825,
    "US-ID": 0.3574126328174683,
    "US-IL": 0.5909658434647515,
    "US-IN": 0.5415843457912679,
    "US-KS": 0.4459654719878213,
    "US-KY": 0.5800111641067517,
    "US-LA": 0.8784096384163151,
    "US-MA": 0.5030300756857854,
    "US-MD": 0.7982066919907438,
    "US-ME": 0.39861305278635034,
    "US-MI": 0.5796580276179659,
    "US-MN": 0.441122469957001,
    "US-MO": 0.5232667689293609,
    "US-MS": 0.9150902392620609,
    "US-MT": 0.42332565497675945,
    "US-NC": 0.6706502343126434,
    "US-ND": 0.44959366010866414,
    "US-NE": 0.4426294029867346,
    "US-NH": 0.38025740595318375,
    "US-NJ": 0.5969998334467363,
    "US-NM": 0.5589197086872406,
    "US-NV": 0.6729547576856125,
    "US-NY": 0.705024013323402,
    "US-OH": 0.5769725177585905,
    "US-OK": 0.5220374308150816,
    "US-OR": 0.4082154983117964,
    "US-PA": 0.6470385974741032,
    "US-RI": 0.5464575064676517,
    "US-SC": 0.6833581670143268,
    "US-SD": 0.387487372278055,
    "US-TN": 0.5530650711142646,
    "US-TX": 0.7423555768608562,
    "US-UT": 0.3365937226357788,
    "US-VA": 0.6293686016255801,
    "US-VT": 0.41401048529181506,
    "US-WA": 0.48246888638659957,
    "US-WI": 0.45037158524409704,
    "US-WV": 0.5208327020351802,
    "US-WY": 0.2736577082812549,
  },
  {
    state: 2020,
    "US-AK": 0.4084012866063256,
    "US-AL": 0.7742183497659386,
    "US-AR": 0.5994292551412396,
    "US-AZ": 0.552886484147714,
    "US-CA": 0.6173823684306952,
    "US-CO": 0.4858744090346667,
    "US-CT": 0.557899433091215,
    "US-DC": 1,
    "US-DE": 0.7498298400192753,
    "US-FL": 0.8602943977116453,
    "US-GA": 0.9366630997667418,
    "US-HI": 0.5030739797054405,
    "US-IA": 0.41510838630545455,
    "US-ID": 0.39341953485292863,
    "US-IL": 0.6229892129289472,
    "US-IN": 0.5625551225062283,
    "US-KS": 0.498154087143417,
    "US-KY": 0.5411981350543971,
    "US-LA": 0.9081294888902187,
    "US-MA": 0.518127966353553,
    "US-MD": 0.7692946450665161,
    "US-ME": 0.41867120598618607,
    "US-MI": 0.5713919023821716,
    "US-MN": 0.4380947950525532,
    "US-MO": 0.5917046694459805,
    "US-MS": 0.8764237807960087,
    "US-MT": 0.3459410064000891,
    "US-NC": 0.6610595683184182,
    "US-ND": 0.5072301532740111,
    "US-NE": 0.4475373966385666,
    "US-NH": 0.41277773256567935,
    "US-NJ": 0.6409059246642969,
    "US-NM": 0.5296571128008448,
    "US-NV": 0.6720647737911027,
    "US-NY": 0.740098574377966,
    "US-OH": 0.588380454785067,
    "US-OK": 0.5654385727432789,
    "US-OR": 0.4209443411754998,
    "US-PA": 0.7192565626624794,
    "US-RI": 0.5720411945614977,
    "US-SC": 0.7316921646927435,
    "US-SD": 0.4763276930002814,
    "US-TN": 0.6374869257758169,
    "US-TX": 0.7488311653589936,
    "US-UT": 0.38958018669877026,
    "US-VA": 0.637996206118932,
    "US-VT": 0.41317095061265,
    "US-WA": 0.4853631833307864,
    "US-WI": 0.46164026408476455,
    "US-WV": 0.5573613699583574,
    "US-WY": 0.4211782422503774,
  },
  {
    state: 2021,
    "US-AK": 0.5466215713416157,
    "US-AL": 0.776443505630036,
    "US-AR": 0.5852196336230407,
    "US-AZ": 0.6096252038204402,
    "US-CA": 0.6473825519336796,
    "US-CO": 0.5092937342049128,
    "US-CT": 0.5491304598806929,
    "US-DC": 1,
    "US-DE": 0.6431072275533534,
    "US-FL": 0.8662281415445539,
    "US-GA": 0.96140699865573,
    "US-HI": 0.5124854351486606,
    "US-IA": 0.4154718485350221,
    "US-ID": 0.39607791177387436,
    "US-IL": 0.6532578537421505,
    "US-IN": 0.5882577818779545,
    "US-KS": 0.5084403745323589,
    "US-KY": 0.6052304286197723,
    "US-LA": 0.8608843617580428,
    "US-MA": 0.5539092440965832,
    "US-MD": 0.7293391209788002,
    "US-ME": 0.5081064419147163,
    "US-MI": 0.5934652598704798,
    "US-MN": 0.4295278534310805,
    "US-MO": 0.5478111072395035,
    "US-MS": 0.9301573063305766,
    "US-MT": 0.33895279055365707,
    "US-NC": 0.7191460332483629,
    "US-ND": 0.46855419389861674,
    "US-NE": 0.4439853429032107,
    "US-NH": 0.3602520397661885,
    "US-NJ": 0.621088853962012,
    "US-NM": 0.5224795187022673,
    "US-NV": 0.6991738117073837,
    "US-NY": 0.7124927264184049,
    "US-OH": 0.613438183113537,
    "US-OK": 0.5469118394140472,
    "US-OR": 0.4340100932768352,
    "US-PA": 0.660356030722118,
    "US-RI": 0.5568565415890577,
    "US-SC": 0.7578132739899324,
    "US-SD": 0.46635915992271465,
    "US-TN": 0.5986648919962951,
    "US-TX": 0.7411913264194758,
    "US-UT": 0.4247444150235446,
    "US-VA": 0.6192305692259603,
    "US-VT": 0.43676741492688104,
    "US-WA": 0.44575304122425724,
    "US-WI": 0.4776012660813189,
    "US-WV": 0.5876475841499734,
    "US-WY": 0.4345059490398049,
  },
  {
    state: 2022,
    "US-AK": 0.6262331149230675,
    "US-AL": 0.8314268392638878,
    "US-AR": 0.6755560815435651,
    "US-AZ": 0.5804140074229845,
    "US-CA": 0.6727969402403835,
    "US-CO": 0.5424229456733726,
    "US-CT": 0.6043607122574801,
    "US-DC": 1,
    "US-DE": 0.7367672259154975,
    "US-FL": 0.855884366535565,
    "US-GA": 0.9963036748996011,
    "US-HI": 0.565766588006671,
    "US-IA": 0.40866150067596,
    "US-ID": 0.46183595525320986,
    "US-IL": 0.646299278194846,
    "US-IN": 0.6279568702826317,
    "US-KS": 0.4905630859585165,
    "US-KY": 0.6390607216286543,
    "US-LA": 0.9527277672036908,
    "US-MA": 0.5177916883160115,
    "US-MD": 0.7807149193588232,
    "US-ME": 0.40014839933755025,
    "US-MI": 0.6378362340461574,
    "US-MN": 0.4420622900915111,
    "US-MO": 0.591477875827246,
    "US-MS": 0.9355509882381375,
    "US-MT": 0.4535320321539224,
    "US-NC": 0.6777233394509419,
    "US-ND": 0.4433257776469917,
    "US-NE": 0.4990519023737931,
    "US-NH": 0.4065920271739076,
    "US-NJ": 0.6676582035871435,
    "US-NM": 0.592011305294221,
    "US-NV": 0.7680987512131973,
    "US-NY": 0.7323854760912261,
    "US-OH": 0.6481415221384411,
    "US-OK": 0.6041066132884313,
    "US-OR": 0.4544969180925697,
    "US-PA": 0.6306441800292102,
    "US-RI": 0.5833188361245072,
    "US-SC": 0.839289566827862,
    "US-SD": 0.40024837872602137,
    "US-TN": 0.6472508480234312,
    "US-TX": 0.7729762206304515,
    "US-UT": 0.47003601291826036,
    "US-VA": 0.6262374588542612,
    "US-VT": 0.4206854890239268,
    "US-WA": 0.4849106556705268,
    "US-WI": 0.48778014949280146,
    "US-WV": 0.5415846552975757,
    "US-WY": 0.46044024023803987,
  },
  {
    state: 2023,
    "US-AK": 0.6148069764609824,
    "US-AL": 0.8241556886997677,
    "US-AR": 0.7190954858473868,
    "US-AZ": 0.5607968342062507,
    "US-CA": 0.6367965452176253,
    "US-CO": 0.4920962050036182,
    "US-CT": 0.6146248294441734,
    "US-DC": 0.9438646450127397,
    "US-DE": 0.810346566279251,
    "US-FL": 0.840791772814837,
    "US-GA": 1,
    "US-HI": 0.5269244617888519,
    "US-IA": 0.46280627484779746,
    "US-ID": 0.41440849827176457,
    "US-IL": 0.652430968553872,
    "US-IN": 0.5967756019544813,
    "US-KS": 0.4865189300557687,
    "US-KY": 0.5836875110951917,
    "US-LA": 0.9806293447539342,
    "US-MA": 0.5277719307603239,
    "US-MD": 0.8226386465048109,
    "US-ME": 0.4108136006358436,
    "US-MI": 0.6189379115823501,
    "US-MN": 0.4532319692386864,
    "US-MO": 0.5792556051835994,
    "US-MS": 0.9732080142884064,
    "US-MT": 0.427601041796072,
    "US-NC": 0.6747892711852346,
    "US-ND": 0.4854419057410409,
    "US-NE": 0.45847072886372786,
    "US-NH": 0.38607766260281656,
    "US-NJ": 0.671106121855202,
    "US-NM": 0.6474786059404539,
    "US-NV": 0.7594004755755105,
    "US-NY": 0.7109702629032024,
    "US-OH": 0.6541217443561764,
    "US-OK": 0.5641321683518293,
    "US-OR": 0.4544166009416619,
    "US-PA": 0.648328488654974,
    "US-RI": 0.6061878141642388,
    "US-SC": 0.7652194501329234,
    "US-SD": 0.550284366116249,
    "US-TN": 0.644353859099787,
    "US-TX": 0.7284256258819561,
    "US-UT": 0.40807255776922746,
    "US-VA": 0.6471727382957717,
    "US-VT": 0.3745774028878916,
    "US-WA": 0.5022106846335476,
    "US-WI": 0.4729294781158598,
    "US-WV": 0.5992079276836194,
    "US-WY": 0.3977591437573069,
  },
  {
    state: 2024,
    "US-AK": 0.514626033970907,
    "US-AL": 0.8591134189918204,
    "US-AR": 0.6544881240513906,
    "US-AZ": 0.5644239992414594,
    "US-CA": 0.610563481915302,
    "US-CO": 0.4519677751122976,
    "US-CT": 0.5819217555795463,
    "US-DC": 0.9313216773187533,
    "US-DE": 0.7588032287257852,
    "US-FL": 0.8065527836106697,
    "US-GA": 1,
    "US-HI": 0.5562755382105753,
    "US-IA": 0.4456696837670379,
    "US-ID": 0.36330724311896834,
    "US-IL": 0.6124155478603646,
    "US-IN": 0.5626103171246297,
    "US-KS": 0.4802933767521201,
    "US-KY": 0.5585711945683745,
    "US-LA": 0.9844762745806775,
    "US-MA": 0.499861411030819,
    "US-MD": 0.8157446999572638,
    "US-ME": 0.445640178600818,
    "US-MI": 0.5999993319174807,
    "US-MN": 0.4367510473826948,
    "US-MO": 0.5654110988349133,
    "US-MS": 0.9881183993360644,
    "US-MT": 0.4186910696486502,
    "US-NC": 0.7028217349486181,
    "US-ND": 0.52626031989435,
    "US-NE": 0.49357006518519314,
    "US-NH": 0.37112464254384636,
    "US-NJ": 0.6193049203293344,
    "US-NM": 0.5805489932926479,
    "US-NV": 0.6351156159643686,
    "US-NY": 0.6862904338724015,
    "US-OH": 0.5789637800839977,
    "US-OK": 0.5378783863218621,
    "US-OR": 0.4303426957706044,
    "US-PA": 0.6008545994753597,
    "US-RI": 0.5155494749006158,
    "US-SC": 0.8340063376444328,
    "US-SD": 0.5457815171036322,
    "US-TN": 0.6244000029746126,
    "US-TX": 0.7498268445046073,
    "US-UT": 0.41319140863942394,
    "US-VA": 0.6009332350600445,
    "US-VT": 0.36715560418927407,
    "US-WA": 0.4742040234308738,
    "US-WI": 0.41196292568729403,
    "US-WV": 0.5530718752020354,
    "US-WY": 0.4705768404802703,
  },
];
