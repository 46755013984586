import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Use am4themes_animated for animations
am4core.useTheme(am4themes_animated);

// Create the heatmap chart
export function createHeatmapChart(containerId, yearData) {
  var chart = am4core.create(containerId, am4maps.MapChart);

  // Set map definition
  chart.geodata = am4geodata_usaLow;

  // Set map projection
  chart.projection = new am4maps.projections.AlbersUsa();

  // Create polygon series
  var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
  polygonSeries.useGeodata = true;

  // Remove the hardcoded data source since we are passing data dynamically
  // polygonSeries.dataSource.url = "https://raw.githubusercontent.com/mdredze/covid19_social_mobility.github.io/master/data/state-index.json";

  // Assign the dynamically passed data (from app.js)
  polygonSeries.data = yearData;

  // Set heatmap rules
  polygonSeries.heatRules.push({
    property: "fill",
    target: polygonSeries.mapPolygons.template,
    min: am4core.color("#3B7DDD").brighten(7),
    max: am4core.color("#3B7DDD").brighten(-0.3),
  });

  // Add heat legend
  let heatLegend = chart.createChild(am4maps.HeatLegend);
  heatLegend.series = polygonSeries;
  heatLegend.align = "right";
  heatLegend.valign = "bottom";
  heatLegend.width = am4core.percent(20);
  heatLegend.marginRight = am4core.percent(2);
  heatLegend.minValue = 0;
  heatLegend.maxValue = 100;

  // Set custom labels for heat legend
  var minRange = heatLegend.valueAxis.axisRanges.create();
  minRange.value = heatLegend.minValue;
  minRange.label.text = "0%";
  var maxRange = heatLegend.valueAxis.axisRanges.create();
  maxRange.value = heatLegend.maxValue;
  maxRange.label.text = "100%";

  // Blank out internal heat legend value axis labels
  heatLegend.valueAxis.renderer.labels.template.adapter.add(
    "text",
    function (labelText) {
      return "";
    }
  );

  // Configure tooltip
  var polygonTemplate = polygonSeries.mapPolygons.template;
  polygonTemplate.tooltipText = "{name}: {value}";
  polygonTemplate.nonScalingStroke = true;
  polygonTemplate.strokeWidth = 0.5;

  // Add hover state
  var hs = polygonTemplate.states.create("hover");
  hs.properties.fill = am4core.color("#042039");

  // Disable zoom on scroll
  chart.maxZoomLevel = 1;
  chart.chartContainer.wheelable = false;

  return chart;
}
